/*
=============== 
Fonts
===============
*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans|Roboto:400,700&display=swap");

/*
=============== 
Variables
===============
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(295, 85%, 18%);
  --clr-primary-2: hsl(294, 72%, 25%);
  --clr-primary-3: hsl(294, 68%, 32%);
  --clr-primary-4: hsl(294, 60%, 38%);
  /* primary/main color */
  --clr-primary-5: #a23dad;
  /* lighter shades of primary color */
  --clr-primary-6: hsl(294, 43%, 51%);
  --clr-primary-7: hsl(293, 48%, 58%);
  --clr-primary-8: hsl(293, 54%, 68%);
  --clr-primary-9: hsl(293, 67%, 85%);
  --clr-primary-10: hsl(295, 58%, 93%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --ff-primary: "Quicksand", sans-serif;
  --ff-secondary: "Quicksand", sans-serif;
  --transition: all 0.3s linear;
  --spacing: 0.25rem;
  --radius: 0.5rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;

  /* smooth scroll */
  scroll-behavior: smooth;
}

/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: var(--ff-secondary);
  background: var(--clr-white);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
img:not(.nav-logo) {
  width: 100%;
  display: block;
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
  font-family: var(--ff-primary);
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

.btn {
  text-transform: uppercase;
  background: var(--clr-primary-5);
  color: var(--clr-primary-1);
  padding: 0.375rem 0.75rem;
  letter-spacing: var(--spacing);
  display: inline-block;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: var(--radius);
}
.btn:hover {
  color: var(--clr-primary-1);
  background: var(--clr-primary-7);
}
/* section */
.section {
  padding: 5rem 0;
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}
@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}
/* added during recording */
.underline {
  width: 5rem;
  height: 0.25rem;
  margin-bottom: 1.25rem;
  background: var(--clr-primary-5);
  margin-left: auto;
  margin-right: auto;
}
.section-title {
  margin-bottom: 4rem;
  text-align: center;
}
.bg-grey {
  background: var(--clr-grey-10);
}
/*
=============== 
Navbar
===============
*/
.nav-links {
  display: none;
}
.nav {
  height: 5rem;
  padding: 1rem;
  display: grid;
  -webkit-box-align: center;
  align-items: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  /* additional stuff */
  background: var(--clr-white);
  position: sticky;
  top: 0;
  z-index: 10;
  /* color value */
  box-shadow: 0 1px 5px var(--clr-primary-9);
}
/* additional stuff */
section,
header {
  scroll-margin-top: 5rem;
}
.nav-center {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}
.nav-header {
  display: grid;
  grid-template-columns: auto 1fr;
  -webkit-box-align: center;
  align-items: center;
}
.nav-btn {
  background: transparent;
  border-color: transparent;
  color: var(--clr-primary-5);
  font-size: 2rem;
  cursor: pointer;
  justify-self: end;
}
@media screen and (min-width: 768px) {
  /* .nav {
    background: var(--clr-primary-10);
  } */
  .nav-btn {
    display: none;
  }
  .nav-links {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
    justify-self: end;
  }
  .nav-links a {
    text-transform: capitalize;
    color: var(--clr-grey-1);
    font-weight: bold;
    letter-spacing: var(--spacing);
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }
  .nav-links a:hover {
    color: var(--clr-primary-5);
  }
  .nav-center {
    display: grid;
    grid-template-columns: auto 1fr;
    -webkit-box-align: center;
    align-items: center;
  }
}
/* fixed navbar */
.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--clr-white);
  z-index: 2;
  box-shadow: var(--light-shadow);
}
/*
=============== 
Sidebar
===============
*/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--clr-grey-10);
  z-index: 20;
  display: grid;
  /* align-items: center;
  justify-content: center; */
  place-items: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  /* add later */
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
/* toggle sidebar */
.show-sidebar {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
.sidebar-links {
  text-align: center;
}
.sidebar-links a {
  font-size: 2rem;
  text-transform: capitalize;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--clr-grey-5);
  letter-spacing: var(--spacing);
  display: inline-block;
  margin-bottom: 1.5rem;
}
.sidebar-links a:hover {
  color: var(--clr-primary-5);
}
.social-icons {
  display: grid;
  margin-top: 3rem;
  width: 20rem;
  /* grid-template-columns: repeat(5, 1fr); */
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
}
.social-icon {
  font-size: 1.5rem;
  color: var(--clr-grey-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.social-icon:hover {
  color: var(--clr-primary-5);
}
.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 3rem;
  background: transparent;
  border: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #bb2525;
  cursor: pointer;
}
.close-btn:hover {
  color: #e66b6b;
}
@media screen and (min-width: 768px) {
  .sidebar {
    display: none;
  }
}
/*
=============== 
Hero
===============
*/
/* underline added to globals */
.hero .underline {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
.hero-img {
  display: none;
}
.hero {
  background: var(--clr-primary-10);
}
.hero-center {
  min-height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
}
.hero-info h4 {
  color: var(--clr-grey-5);
}
.hero-icons {
  justify-items: start;
}
.hero-btn {
  margin-top: 1.25rem;
}
@media screen and (min-width: 992px) {
  .hero-img {
    display: block;
    position: relative;
  }
  .hero-center {
    grid-template-columns: 1fr 1fr;
  }

  /* .about-img::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.25rem solid var(--clr-primary-5);
    top: 2rem;
    right: -2rem;
    border-radius: var(--radius);
  } */
}
.hero-photo {
  max-width: 25rem;
  height: 25rem;
}

/*
=============== 
About
===============
*/
.about-title {
  text-align: left;
  margin-bottom: 2rem;
}
.about-title .underline {
  margin-left: 0;
}
.about-center {
  display: grid;
  gap: 3rem 2rem;
}
.about-img {
  justify-self: center;
}
.about-photo {
  max-width: 20rem;
  height: 20rem;
}
@media screen and (min-width: 992px) {
  .about-center {
    grid-template-columns: 1fr 1fr;
  }
  .about-img {
    position: relative;
  }
  /* .about-img::before {
    left: -2rem;
  } */
  .about-info {
    align-self: center;
  }
}

/*
=============== 
Skills
===============
*/
.skills {
  background: var(--clr-grey-10);
}
.single-skill {
  background: var(--clr-white);
  padding: 1.5rem 2rem;
  border-radius: 0.25rem;
  transition: var(--transition);
  border-top: 4px solid transparent;
  text-align: center;
}
.single-skill:hover {
  border-color: var(--clr-primary-5);
  box-shadow: var(--dark-shadow);
}
.skill-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background: var(--clr-primary-5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  color: var(--clr-white);
  margin-left: auto;
  margin-right: auto;
}
.single-skill h4 {
  margin-top: 1rem;
}
.single-skill p {
  margin-bottom: 0;
}
.skills-center {
  display: grid;
  gap: 1rem;
}
@media screen and (min-width: 768px) {
  .skills-center {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 992px) {
  .skills-center {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
/*
=============== 
Projects
===============
*/
.projects {
  background: var(--clr-grey-10);
}

.project {
  display: grid;
  margin-bottom: 4rem; /* distance between projects */
}
.project-img {
  border-radius: var(--radius);
  position: relative; /* setting the parent relative as we will use position:absolute for .project-img::before */
  height: 15rem;
}
.project-photo {
  height: 100%; /* we set/control height in the parent so we add height:100% (width already at 100% in the global styles) */
  object-fit: cover; /* so the image is not distorted */
  border-radius: var(--radius);
}
.project-img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom right, var(--clr-primary-5), #222);
  opacity: 0.85;
  transition: var(--transition);
  border-radius: var(--radius);
}
.project:hover .project-img::before {
  opacity: 0; /* setting opacity to 0 to hide on hover */
}
.project-info {
  background: var(--clr-white);
  padding: 1rem 0; /* only vertical padding, will add horizontal on big screen */
  border-radius: var(--radius);
}
.project-number {
  display: inline-block;
  font-size: 1.25rem;
  color: var(--clr-primary-5);
  margin-bottom: 0.75rem;
}
.project-info h3 {
  font-weight: 500;
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
}
.project-desc {
  /* word-spacing: 15px; */
  color: var(--clr-grey-3);
}
.project-stack span {
  display: inline-block;
  background: var(--clr-grey-9);
  color: var(--clr-grey-5);
  margin-right: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: var(--radius);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.85rem;
}
.project-stack {
  margin-bottom: 1rem;
}
.project-link {
  color: var(--clr-primary-5);
  font-size: 1.25rem;
  margin-right: 0.5rem;
  transition: var(--transition);
}
.project-link:hover {
  color: var(--clr-primary-1);
}

@media screen and (min-width: 576px) {
  .project-img {
    height: 19rem;
  }
}
@media screen and (min-width: 768px) {
  .project-img {
    height: 22rem;
  }
}

@media screen and (min-width: 992px) {
  .project {
    grid-template-columns: repeat(12, 1fr);
    align-items: center;
  }
  .project-img {
    grid-column: 1 / span 8;
    grid-row: 1/1;
    height: 30rem;
    box-shadow: var(--dark-shadow);
  }
  .project-info {
    border-radius: var(--radius);
    box-shadow: var(--dark-shadow);
    z-index: 1;
    grid-column: 5/12;
    grid-row: 1/1;
    padding: 1rem 2rem;
  }
  .project:nth-of-type(even) .project-img {
    grid-column: 5 / -1;
    grid-row: 1/1;
  }
  .project:nth-of-type(even) .project-info {
    grid-column: 2 / span 7;
    grid-row: 1/1;
  }
}

/*
=============== 
Footer
===============
*/
.footer {
  padding-bottom: 2rem;
  background: #222;
  display: grid;
  place-items: center;
}
.footer .social-icons {
  margin-top: 2rem;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}
.footer .social-icon {
  color: var(--clr-primary-5);
}
.footer .social-icon:hover {
  color: var(--clr-primary-10);
}
.footer p {
  font-size: 1.25rem;
  text-transform: capitalize;
}
